import {reactive, toRefs, onBeforeMount,onMounted,defineComponent,getCurrentInstance, watch} from 'vue';
import UnitUtil ,{IUnitDataObj} from "./unitUtil";
const updateKey='update:modelValue';
export default defineComponent ({
    name: "split",
    props: {
        modelValue: {
            type: [Number,String],
            default: 0 || ''
        },
        type: {type: String,default: 'numberInput'},

        min: {type: Number,default: 0},
        max: {type: Number,default: 9999999},
        unit:{type:String,default: '元'}
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUnitDataObj=reactive<IUnitDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                unit:props.unit,
                numberAttrs:{
                    defaultValue:props.modelValue,
                    type:props.type,
                    min:props.min,
                    max:props.max
                },
                inputAttrs:{
                    defaultValue:''
                }
            }
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new UnitUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })

        watch(() => props.modelValue,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.defaultValue=newValue;
            dataObj.otherParams.inputAttrs.defaultValue=newValue;
        })
        const numberInputChange=(currentValue: number, oldValue: number)=>{
            context.emit(updateKey,currentValue);
            context.emit('changeHandler',currentValue,oldValue);
        }
        const inputChange=(currentValue: string)=>{
            context.emit(updateKey,currentValue);
            context.emit('changeHandler',currentValue);
        }
        watch(() => props.unit,async (newValue,oldValue) => {
            dataObj.otherParams.unit=newValue;
        })
        watch(() => props.type,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.type=newValue;
        })
        watch(() => props.min,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.min=newValue;
        })
        watch(() => props.max,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.max=newValue;
        })
        return{
            ...toRefs(dataObj),numberInputChange,inputChange
        }
    },
    components: {}
});